import { useEffect, useState } from 'react';
import { postApi } from '../../api/index';
import { signInAsync } from '../../authRedux/store';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Modal } from '@mui/material';

const SignUp = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [open, setOpen] = useState(false);

    const handleOpenModal = () => {
        setOpen(true);
    };

    const handleCloseModal = () => {
        setOpen(false);
    };

    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        phone_number: '',
        email: "",
        password: '',
        confirm_password: '',
    });

    const [errors, setErrors] = useState({
        email: '',
        password: '',
        confirm_password: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validation logic (same as before)
        const newErrors = {};
        if (!formData.email) {
            newErrors.email = 'Email is required';
        }
        if (formData.password.length < 8) {
            newErrors.password = 'Password must be at least 8 characters long';
        }
        if (formData.password !== formData.confirm_password) {
            newErrors.confirm_password = 'Passwords do not match';
        }
        if (!e.target.elements.terms.checked) {
            newErrors.terms = 'You must accept the Terms and Conditions';
        }
        setErrors(newErrors);

        // await postApi('/users/register/', formData).then((res)=>{console.log(res)})

        // If there are no validation errors
        if (Object.keys(newErrors).length === 0) {

            try {

                const response = await postApi('/users/register/', {
                    first_name: formData.first_name,
                    last_name: formData.last_name,
                    phone_number: formData.phone_number,
                    email: formData.email,
                    password: formData.password,
                });

                // Check if the API call was successful
                if (response) {
                    // Handle the successful response, e.g. show a success message
                    console.log('Sign-up successful', response);
                    // Reset the form data after successful sign-up
                    e.preventDefault();
                    try {

                        await dispatch(signInAsync(formData.email, formData.password));

                        const from = location.state?.from || '/dashboard';
                        navigate(from);

                    } catch (error) {
                        console.error('Login failed:', error);
                        e.preventDefault();
                        // Handle login failure, show error message, etc.
                        // ... (rest of your error handling code)
                    }
                }
            } catch (error) {
                // Handle API call errors, which are already handled in postApi function
                console.error('Sign-up failed', error);
            }
        }
    }
    return (
        <form onSubmit={handleSubmit}>
            <div className="grid md:grid-cols-2 gap-y-4 md:w-[500px] md:gap-x-4">
                <div>
                    <label className="block text-sm mb-2">First Name</label>
                    <input
                        type="text"
                        id="first_name"
                        name="first_name"
                        className="py-3 px-4 block w-full border border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500"
                        required
                        onChange={handleChange}
                        value={formData.first_name}
                    />
                </div>

                <div>
                    <label className="block text-sm mb-2 ">Last Name</label>
                    <input
                        type="text"
                        id="last_name"
                        name="last_name"
                        className="py-3 px-4 block w-full border border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500"
                        required
                        onChange={handleChange}
                        value={formData.last_name}
                    />
                </div>

                <div>
                    <label className="block text-sm mb-2 ">Contact Number</label>
                    <input
                        type="phone_number"
                        id="phone_number"
                        name="phone_number"
                        className="py-3 px-4 block w-full border border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500"
                        required
                        onChange={handleChange}
                        value={formData.phone_number}
                    />
                </div>

                <div>
                    <label className="block text-sm mb-2 ">Email address</label>
                    <div className="relative">
                        <input
                            type="email"
                            id="email"
                            name="email"
                            className="py-3 px-4 block w-full border border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500"
                            required
                            aria-describedby="email-error"
                            onChange={handleChange}
                            value={formData.email} />

                        <div className="hidden absolute inset-y-0 right-0 flex items-center pointer-events-none pr-3">
                            <svg className="h-5 w-5 text-red-500" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" aria-hidden="true">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                            </svg>
                        </div>
                    </div>
                    {errors.email && <p className="text-xs text-red-600 mt-2">{errors.email}</p>}
                </div>

                <div>
                    <label className="block text-sm mb-2 ">Password</label>
                    <div className="relative">
                        <input type="password"
                            id="password"
                            name="password"
                            className="py-3 px-4 block w-full border border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500"
                            required
                            aria-describedby="password-error"
                            onChange={handleChange}
                            value={formData.password} />
                        <div className="hidden absolute inset-y-0 right-0 flex items-center pointer-events-none pr-3">
                            <svg className="h-5 w-5 text-red-500" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" aria-hidden="true">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                            </svg>
                        </div>
                    </div>
                    {errors.password && <p className="text-xs text-red-600 mt-2">{errors.password}</p>}
                </div>

                <div>
                    <label className="block text-sm mb-2 ">Confirm Password</label>
                    <div className="relative">
                        <input type="password"
                            id="confirm_password"
                            name="confirm_password"
                            className="py-3 px-4 block w-full border border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500"
                            required
                            aria-describedby="confirm-password-error"
                            onChange={handleChange}
                            value={formData.confirm_password} />
                        <div className="hidden absolute inset-y-0 right-0 flex items-center pointer-events-none pr-3">
                            <svg className="h-5 w-5 text-red-500" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" aria-hidden="true">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                            </svg>
                        </div>
                    </div>
                    {errors.confirm_password && <p className="text-xs text-red-600 mt-2">{errors.confirm_password}</p>}
                </div>

                <div className="flex items-center">
                    <div className="flex">
                        <input
                            id="terms"
                            name="terms"
                            type="checkbox"
                            className="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 focus:ring-blue-500 cursor-pointer"
                            required // This attribute makes the checkbox required
                        />
                    </div>
                    <div className="ml-3">
                        <label className="text-sm ">
                            I accept the <a className="text-blue-600 decoration-2 hover:underline font-medium cursor-pointer" onClick={handleOpenModal}>Terms and Conditions</a>
                        </label>
                    </div>
                </div>
                <Modal
                    open={open}
                    onClose={handleCloseModal}
                    className='flex justify-center'
                >
                    <div className='overflow-scroll overflow-x-hidden w-3/4 lg:w-1/2 px-10 py-10 my-20 lg:my-10 bg-white font-custom'>
                        <h5 className='font-bold'>Terms and conditions</h5>
                        <span className='text-sm'>Welcome to Himalayan Trail Running, a trail-running organizing company. We are excited to help you plan and participate in your next trail running event!
                            <br /> <br />
                            Please read these terms and conditions carefully before registering for or participating in any of our events. By registering for or participating in an event, you agree to be bound by these terms and conditions.
                            <br /><br />
                            <strong>Registration</strong><br />
                            <ul className='list-disc pl-4'>
                                <li>To register for an event, you must be at least 18 years of age, or have written consent from your guardian to participate in our event.</li>
                                <li>You must provide accurate and complete information when registering.</li>
                                <li>Registration fees are generally non-refundable and non-transferable.</li>
                                <li>You may not register for an event on behalf of another person without their express permission.</li>
                            </ul>
                            <br />
                            <strong>Participation</strong><br />
                            All participants must be in good physical condition and able to complete the distance of the event.All participants must be in good physical condition and able to complete the distance of the event.
                            <ul className='list-disc pl-4'>
                                <li>Participants must stay on the marked trail.</li>
                                <li>Participants must yield to other trail users, including hikers, mountain bikers, and equestrians.</li>
                                <li>Participants must carry their own trash and leave no trace.</li>
                                <li>Participants must obey all speed limits and other traffic laws.</li>
                                <li>Participants must wear appropriate clothing and footwear for the event.</li>
                                <li>Participants must carry their own hydration and nutrition.</li>
                                <li>Participants must be aware of their surroundings and take precautions to avoid injury.</li>
                            </ul>
                            <br />
                            <strong>Refunds and Cancellations</strong> <br />
                            <ul className='list-disc pl-4'>
                                <li> Registration fees are generally non-refundable and non-transferable. However, we may offer refunds or transfers in certain cases, such as if the event is cancelled due to circumstances beyond our control, such as severe weather or a public health emergency.</li>
                                <li> If you need to cancel your registration for any reason, please contact us as soon as possible. We may be able to offer you a partial refund, depending on the circumstances.</li>
                            </ul>
                            <br />

                            <strong>Liability</strong> <br />
                            <ul className='list-disc pl-4'>
                                <li> Himalayan Trail Running is not responsible for any injuries, illnesses, or losses that occur during or as a result of an event.</li>
                                <li>  You agree to release Himalayan Trail Running and its affiliates, officers, directors, agents, and employees from any and all liability arising out of or in connection with your participation in an event.</li>
                            </ul>
                            <br />

                            <strong>Other Terms</strong> <br />
                            <ul className='list-disc pl-4'>
                                <li>We reserve the right to modify these terms and conditions at any time.</li>
                                <li>Any disputes arising out of these terms and conditions will be governed by the laws of the government of Nepal.</li>
                                <li>Participants agree to receive promotional emails and updates about future events from Himalayan Trail Running Private Limited.</li>
                                <li>Participants grant the Company full rights to use event multimedia for promotional purposes.</li>
                                <li>By registering for or participating in an event, you agree to be bound by these terms and conditions.</li>
                            </ul>
                            <br />
                            <strong>Additional Details</strong><br />
                            <ul className='list-disc pl-4'>
                                <li>We encourage all participants to review the event website carefully before registering. The event website will contain information about the course, the aid stations, and other important details.</li>
                                <li>We also encourage all participants to join our Facebook group or email list to receive updates about the event.</li>
                                <li>If you have any questions or concerns, please do not hesitate to contact us.</li>
                            </ul><br />
                            We look forward to seeing you at your next trail running event!
                        </span>

                    </div>
                </Modal>



                <button type="submit" className="py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-red-600 text-white hover:bg-red-700 ease-in duration-200 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 transition-all text-sm">Sign up</button> </div>
        </form>
    )
}

export default SignUp;