import React, { useState } from "react";
import Modal from '@mui/material/Modal';
import { MediaAPI } from "../../../api/_constant";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const EventPricing = ({ event }) => {

    const [currentIndex, setCurrentIndex] = useState(0);
    const [selectedAddon, setSelectedAddon] = useState(null);
    const [open, setOpen] = useState(false);

    const handleOpenModal = (addon) => {
        setSelectedAddon(addon);
        setOpen(true);
    };

    const handleCloseModal = () => {
        setSelectedAddon(null);
        setOpen(false);
    };


    return (
        <section id='pricing' className='scroll-mt-32 pb-10'>
            <h5 className='font-extrabold tracking-wide pb-4'>Pricing</h5>
            <div className="flex flex-row flex-wrap md:flex-nowrap items-start  gap-8">
                <div className="w-full md:basis-1/2 border-2">
                    <table className="w-full divide-y-2 divide-gray-200">
                        <thead>
                            <tr>
                                <th scope="col" className="p-3 py-2 text-left text-xs font-medium text-gray-500 uppercase">Distance</th>
                                <th scope="col" className="pr-3 py-2 text-left text-xs font-medium text-gray-500 uppercase">Nepali</th>
                                <th scope="col" className="pr-3 py-2 text-left text-xs font-medium text-gray-500 uppercase">SAARC</th>
                                <th scope="col" className="pr-3 py-2 text-left text-xs font-medium text-gray-500 uppercase">International</th>
                            </tr>
                        </thead>
                        <tbody>
                            {event &&
                                event.category_details &&
                                event.category_details.length > 0 &&
                                event.category_details.map(category => (
                                    <tr key={category.name}>
                                        <td className="p-3 py-2 text-sm font-medium text-gray-800 ">{category.name}</td>
                                        <td className="pr-3 py-2 text-sm font-medium text-gray-800 ">Rs. {Number(category.nepali_price).toLocaleString('en-NP')}</td>
                                        <td className="pr-3 py-2 text-sm font-medium text-gray-800 ">Rs. {Number(category.saarc_price).toLocaleString('en-NP')}</td>
                                        <td className="pr-3 py-2 text-sm font-medium text-gray-800 ">Rs. {Number(category.international_price).toLocaleString('en-NP')}</td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
                <div>
                    <div className='[&>ul]:text-sm [&>p]:text-sm [&>p]:leading-relaxed [&>ul]:leading-relaxed [&>ul]:list-disc [&>ul]:indent-2 [&>ul]:pl-4 ' dangerouslySetInnerHTML={{ __html: event.legal_fine_print }} />
                </div>
            </div>

            {event.addon_details.length > 0 && (
                <div className="pt-8">
                    <h6 className="font-bold pb-2">Addons</h6>
                    <p className="text-sm pb-2">Here is a list of optional event addons available for purchase to enhance your experience:</p>
                    <div className="overflow-x-scroll sm:overflow-x-hidden ">
                        <table className="w-full divide-y-2 divide-gray-200 border-2">
                            <thead>
                                <tr>
                                    <td scope="col" className="p-3 py-2 text-left text-xs font-medium text-gray-500 uppercase">Addons</td>
                                    <td scope="col" className="p-3 py-2 text-left text-xs font-medium text-gray-500 uppercase">Nepali</td>
                                    <td scope="col" className="p-3 py-2 text-left text-xs font-medium text-gray-500 uppercase">SAARC</td>
                                    <td scope="col" className="p-3 py-2 text-left text-xs font-medium text-gray-500 uppercase">International</td>
                                    <td></td>
                                </tr>
                            </thead>
                            <tbody>
                                {event.addon_details
                                    .slice() // Create a shallow copy of the array to avoid mutating the original
                                    .sort((a, b) => a.name.localeCompare(b.name)) // Sort alphabetically by name
                                    .map((value, index) => (
                                        <tr key={index}>
                                            <td className="p-3 py-2 text-sm  text-gray-800">
                                                {value.name}
                                            </td>
                                            <td className="p-3 py-2 text-sm  text-gray-800">
                                                {value.nepali_price == 0 ? '-' : `Rs. ${Number(value.nepali_price).toLocaleString('en-NP')}`}
                                            </td>
                                            <td className="p-3 py-2 text-sm  text-gray-800">
                                                {value.saarc_price == 0 ? '-' : `Rs. ${Number(value.saarc_price).toLocaleString('en-NP')}`}
                                            </td>
                                            <td className="p-3 py-2 text-sm  text-gray-800">
                                                {value.international_price == 0 ? '-' : `Rs. ${Number(value.international_price).toLocaleString('en-NP')}`}
                                            </td>
                                            <td><button className="p-3 py-2 text-sm font-medium text-gray-800 hover:text-red-600" type='button' onClick={() => handleOpenModal(value)}>View Details</button></td>
                                        </tr>
                                    ))}


                            </tbody>
                        </table>
                    </div>
                    <Modal
                        open={open}
                        onClose={handleCloseModal}
                        aria-describedby={`modal-modal-description-${selectedAddon?.id}`}
                        className='flex justify-center font-custom'
                    >
                        <div className='overflow-scroll overflow-x-hidden w-3/4 lg:w-1/2 px-10 py-2 my-20 lg:my-10 bg-white'>
                            <CarouselProvider
                                naturalSlideWidth={100}
                                naturalSlideHeight={125}
                                totalSlides={selectedAddon?.medias?.length}
                                visibleSlides={1}
                                infinite={1}
                                isPlaying={1}
                                currentSlide={0}
                                isIntrinsicHeight={1}
                            >
                                <div className='relative py-8'>
                                    <ButtonBack className={`absolute top-[50%] left-4 z-20 text-white hover:text-red-600 ease-in duration-200 ${selectedAddon?.medias?.length < 2 ? "hidden" : ''}`} ><ArrowBackIosIcon /></ButtonBack>
                                    <Slider className="h-80 overflow-hidden flex items-center justify-center rounded-lg">
                                        {selectedAddon?.medias.map((image, index) => (
                                            <Slide key={`test_${index}`}>
                                                <img key={`test2_${index}`} className='h-80 w-full object-cover object-center' src={`${MediaAPI}${image.src}`} />
                                            </Slide>
                                        ))}
                                    </Slider>
                                    <ButtonNext className={`absolute top-[50%] right-4 z-20 text-white  hover:text-red-600 ease-in duration-200 ${selectedAddon?.medias?.length < 2 ? "hidden" : ''}`}><ArrowForwardIosIcon /></ButtonNext>
                                </div>
                            </CarouselProvider>

                            <div id={`modal-modal-description-${selectedAddon?.id}`}>

                                <h5 className='font-bold'>{selectedAddon?.name}</h5>
                                <div className='[&>ul]:text-sm [&>p]:text-sm [&>p]:leading-relaxed [&>ul]:leading-relaxed [&>ul]:list-disc [&>ul]:indent-2 [&>ul]:pl-4 py-4  '
                                    dangerouslySetInnerHTML={{ __html: selectedAddon?.description }}
                                />
                            </div>
                        </div>
                    </Modal>
                </div>
            )}


        </section>
    )


}

export default EventPricing;