import React, { useEffect, useState } from 'react';
import { get } from '../api/index';

const BlogsArchive = () => {
    const [blogs, setBlogs] = useState([]);
    useEffect(() => {
        get('/content/blogs/?is_active=true')
            .then((res) => {
                if (res) {
                    setBlogs(res['results']);
                }
            })
            .catch((error) => {
                console.error('Error occurred while fetching data:', error);
            });
    }, []);
    console.log(blogs);

    return (
        <>
            <div className=" pt-20 font-custom">
                <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
                    <div className="mx-auto max-w-2xl md:mx-0">
                        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Blogs</h2>
                        <p className="mt-2 text-sm  text-gray-600">
                            Dive into our collection of insightful articles and stories about the world of trail running. Explore expert tips, personal experiences, and more in our blog section.
                        </p>
                    </div>
                </div>
                <div className='mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8 flex flex-wrap flex-col space-y-8 font-custom pb-10'>
                    {blogs?.map((blog, index) => (
                        <article key={index} className='flex flex-row items-start justify-between mb-16' >
                            <div className='w-full flex flex-col md:flex-row gap-8 relative'>
                                <div className='md:w-64 w-full flex-shrink-0 md:relative'>
                                    <img className='rounded-md object-cover w-full h-full object-center overflow-hidden md:absolute' src={blog.cover_image} alt="" />
                                </div>
                                <div className='col-span-1 md:col-span-2 lg:col-span-3 flex flex-col items-start justify-between'>
                                    <div className="flex items-center gap-x-4 text-xs">
                                        <time className='text-gray-500'>
                                            {new Date(blog.updated_at).toLocaleString('en-US', {
                                                month: 'short',
                                                day: '2-digit',
                                                year: 'numeric',
                                                timeZone: 'Asia/Kathmandu'
                                            })}</time>
                                        {blog.tag_details?.map((tag, index) => (
                                            <span key={index} className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100">
                                                {tag.name}
                                            </span>
                                        ))}
                                    </div>
                                    <div className="group relative">
                                        <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-red-600">
                                            <a href={`/blogs/${blog.slug}`}>
                                                <span className="absolute inset-0" />
                                                {blog.title}
                                            </a>
                                        </h3>
                                        <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">{blog.sub_title}</p>
                                    </div>

                                    <hr />
                                    <div className="relative mt-8 flex items-center gap-x-4">
                                        <img src={blog?.author_details?.profile_image} alt="" className="h-10 w-10 rounded-full bg-gray-50" />
                                        <div className="text-sm leading-6">
                                            <p className="text-sm font-semibold text-gray-900">
                                                <span className="absolute inset-0" />
                                                {blog.author_details?.first_name} {blog.author_details?.last_name}

                                            </p>
                                            <ul>
                                                <li className="inline-block relative pr-6 last:pr-0 last-of-type:before:hidden before:absolute before:top-1/2 before:right-2 before:-translate-y-1/2 before:w-1 before:h-1 before:bg-gray-300 before:rounded-full ">
                                                    {blog.author_details.designation}
                                                </li>
                                                <li className="inline-block relative pr-6 last:pr-0 last-of-type:before:hidden before:absolute before:top-1/2 before:right-2 before:-translate-y-1/2 before:w-1 before:h-1 before:bg-gray-300 before:rounded-full ">
                                                    {blog.minutes_to_read} min read
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </article>
                    ))}
                </div>
            </div >
        </>
    )
}

export default BlogsArchive;